/** Landing Palette  - base.scss **/


* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 65px 0 0;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  color: $t-color-secondary;
  font-size: 16px;
  //line-height: 34px;
  font-family: $font-primary;
  font-weight: 300;
  letter-spacing: .32px;

  background-color: #ffffff;
  overflow-x: hidden;
}

.page_landing_palette {

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: 300;
  }

  img {
    display: block;
  }


  .collapse:not(.show) {
    display: none;
  }

  .mainWrap {}

  .wrapper {
    padding: 65px 0 0;
  }

  .header {
    height: 60px;
    background: $color-primary;
    text-align: center;
    display: flex;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    color: #fff;

    .logo {
      height: 100%;

      img {
        height: 100%;
        text-align: center;
        margin: 0 auto;
      }
    }

    div {
      flex-grow: 3;
    }
  }
}