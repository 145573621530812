.header_landing{
  position: fixed;
  width: 100%;
  padding: 0 18px;
  top: 0;
  flex: 0 1 100%;       
  display: flex;
  justify-content: space-between;          
  align-items:center;
  background-color: #3C3C3B;
  z-index:1;
  height:65px;
  .back{
    height:65x;
    min-width:65x;
    img {
      height:100%;
    }
  }
  .logo {
    height:30px;
    margin-top:18px;
  }
}
.cmp_heading{
  h2{
    font-size: 30px;
    margin: 40px 0 80px 36px;
    padding:0;
    font-weight: normal;
  }
}
.page_landing_palette{
    

  .filters_footer{  
    
    flex: 0 1 25%;

  

  }


  color:#3C3C3B;
  line-height: 20px;

  .filters_group_footer {
    
    .filters_items{
      padding: 40px 36px 20px 36px;
      
      justify-content: space-between; 
      gap: 5px;    
      .filters_item_box{        
        font-size:18px;
        display:block;
        flex: 0 1 22%;       
        text-align: center;
        border: none;
        padding:0px;
        margin:0px;
        // word-break: break-word;
        .content{
          font-size: 12px;
          font-weight: normal;

          display: block;
        }
        img{
          margin: 0 auto;
          /*border:2px solid #3C3C3B;          */
          border-radius: 50px;
        }
        span{ display: block; }
      }

      
    }

    .hide {
    display: none;
    }



  }
 

  .filters_group {
    .title {
      padding-left:36px;
      margin-top:44px;
      font-size:30px;
    }

    .filters_item{      
        border-top:1px solid #AAC8E7;        
        border-radius: 20px 20px 0 0;
        box-shadow: 0 1px 3px rgba(0,0,0,0.3);
        padding: 0px 36px 30px;      
        margin-top: -20px;
        font-weight: bold;
        min-height:90px;
        .content {
          justify-content: space-between;
          display: flex;          
          align-items:center;
        }
    }
    
  }
  .listContainer{
    .listModels {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;    
      margin:0 36px;
      gap:20px;
      padding-bottom:130px;
      
      .product-content{            
       flex: 0 1 40%;       
       img {
          width: 100%;
        }
       }
    }
  }

}
.productDetail {
  .zoom{
    position:absolute;
    width:80%;
    img{
      margin:0 0 0 auto;
      width:50px;
    }
  }
  .basicInfo{
    margin-top: 15px;
    border:1px solid #3C3C3B;    
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .label, .txt{
        flex: 0 1 50%;
        text-align: left;
        display: inline-block;
        width: 50%;        
        padding: 5px 0 5px 20px;
        border-bottom:1px solid #3C3C3B;
        word-break: break-word;
      }
      .txt{
        border-left:1px solid #3C3C3B;
      }
      .last{
        border-bottom:0;
      }

   
    
  }
  .section-title{
    text-align: left;
    margin: 26px 0;
  }
  .description{
    margin: 26px 0;
  }
  .sku{
    font-size: 28px;
    color: #999;
  }
  padding: 30px;
  text-align: center;
  .mainImage{    
    img {
      margin: 0 auto;
      width: 80%;
    }
  }
  .product-colors-container{

      display: grid;
      grid-template-columns: repeat(auto-fill, 50px);
      
      justify-content: space-between;    
      gap:25px;
      .product-color{
         
        height: 50px;
        
        background-size:50px;      
        border-radius:50px;
        
      }
      

  }
}
.product_list_container{
  display: flex;
  flex-direction: column;
  justify-content:start;
  height: calc(100% - 115px);
  position: absolute;
  margin-top:20px;
  .product-description{
    margin-top:10px;
    text-align: center;
    font-weight:bold;
  }
}
/*
.colazione, .breakfast { background-color:#AAC8E7;   }
.cucina, .food-preparation { background-color:#D5E4F3;   }
.cookware { background-color:#b8d3ed }
.caffe, .coffee { background-color:#EEF4FA }
*/
.all { background-color:#FFFFFF; }
.popUpImages{
  position:absolute; 
  top:0;
  left:0;
  width:100%;
  height:100%;
  margin:0 ;
  padding:0;
  z-index:100000;
  background:#FFF;
  .selectedImage {
    border: 2px solid #3C3C3B; 
    border-radius: 30px;
    display: inline-block;
    overflow: hidden;
    width:80%;
    img{
      width: 100%;
    }
  }
  .close {
    margin:20px 0;
    text-align: right;
    padding-right:20px;
    img{
      margin:0 0 0 auto; 
      width:70px;
    }
  }
  .othersImage{
    padding-top:20px;
    display:grid;
    
    grid-template-columns: repeat(auto-fill, 22%);
    justify-content: space-between;    
    margin: 0 15px;
    
    width:80%;
    margin: 0 auto;
    .otherImage{
        overflow: hidden;
        margin-top:20px;    
        text-align: left;
        .imgBorder{      
          border: 2px solid #3C3C3B; 
          border-radius: 20px;
          overflow: hidden;
          display: inline-block;
          img{
            margin: 5px auto;
            max-width: 80%;
          }
        }
    }

  }
  .sku{
    margin-top:55px;      
    padding:5px;
  }
}
.menu{
  /*padding-bottom:30px;*/
}

.page_landing_palette .filters_group_footer .filters_items{
  display: none;
}

.filters_close { 
  max-height:90px; 
  transition: max-height 400ms ease-in-out;
}

.filters_open {
  max-height: calc(100vh - 65px);
  transition: max-height 400ms ease-in-out;
  overflow: scroll;
  .filters_items {
    display: grid!important;
    grid-template-columns: repeat(auto-fill, 70px);    
  }
}



.filters_open{
    position: absolute;
    bottom: 0;
    background-color: #FFF;
    width:100%;
}

.placeholderColor{
    margin: 0 auto;    
    border-radius: 50px;
    min-height: 70px;
    width: 70px;
}


.selected{
  min-height: 20px!important;
}

.selected_element{
  min-height: 50px;
  padding: 0 10px 0 36px; 
  .content {
          justify-content: space-between;
          display: flex;  
          align-items:center;        
          font-size: 18px;
          font-weight: bold;
        }
}

.selected_element_family{
  height: 70px;
  margin-bottom:-20px;

}

.rounded_top {
  border-top:1px solid #ccc;        
  border-radius: 20px 20px 0 0;
}

.iconFamily{
  min-height:50px;
}

.filters_open .selected_element {
  margin-bottom:-1px;
  height: 30px;
}

.selected{
  background-color: #85ADD6;
  border: none!important;
}
.color .selected{
  background-color: #85ADD6;
  border: 2px solid #3C3C3B!important;
}

.product-color.selected{
  
  box-shadow: inset 0px 0px 0px 2px #3C3C3B;
}

.filters_footer_wrapper
{
  position:fixed;
  bottom:0;
  width:100%;
}

.content{
  font-size: 18px;
  line-height: 14px;
}

.page_landing_palette .filters_group.colors .filters_item {
  border:none;
  padding-top:10px;
}


.filters_items::after{
    content: "";
    flex: 0 1 22%;
}

.color .filters_items::after{
    content: "";
    flex: 1;
}

.messageMobile{
  background-color: #fff;
  padding: 30px;
  text-align: center;
  color: #000;
  max-width: 1024px;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  margin-top:32.5px;
}

.messageMobile .title{
  margin: 0 0 0 auto;  
  padding-bottom:35px;
  font-size: 32px;
}
.messageMobile .content{
  font-size: 22px;
  line-height: initial;
  img {
  margin: 10px auto;
  }
}

.retailer{           
    text-align: center;
    padding: 15px 0;
    font-weight: bold;
    a {
      color: inherit;
      text-decoration: none;
    }
}