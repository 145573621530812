/** BannerTop **/

/* Version 2 */
.bannerTop{
  margin:0;
  
  
  color: #fff;
  height: 100%;
  
  justify-content: center;
  .title{
    font-size: 1.6rem;
    padding-bottom:25px;
    font-family: $font-secondary;
    font-weight: 400;
    &:empty{      
      margin:0;      
    }
  }
  .subTitle{
    font-size: 1rem;
    letter-spacing: .32px;
    line-height: 1.5rem;
  }
  .gallery{
    top:0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    z-index: -1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bannerTop_container{
    margin:40px 36px;
  }
  .cta{
    width:100%;
    background-color:#3C3C3B;
    display: block;
    text-align: center;
    padding:20px;
    border-radius: 20px;
    margin-top:80px;
    color:#fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight:bold;
    text-decoration: none;
  }
}

