$fonts: "https://sda.smeg.com/webfiles/latest/fonts/";
@font-face {
  font-family: 'Muli';
  src: url(#{$fonts}Muli/Muli-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url(#{$fonts}Muli/Muli-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  //src: url(#{$fonts}Muli/Muli-Light.ttf) format('truetype');
  src: url(#{$fonts}Muli/Muli-Light.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url(#{$fonts}Muli/Muli-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Light.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Didot';
  src: url(#{$fonts}Didot/GFSDidot-Regular.ttf) format('truetype');
}
